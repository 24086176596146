import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import logo from './lab-beaker-icon.png';
import './App.css';
import './customStyles.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function HalfLifeGraph({ initialQuantity, halfLife, unit, elapsedTime }) {
    const currentPoint = elapsedTime / (60 * 60 * 1000); // Convert to hours
    const currentQuantity = initialQuantity * Math.pow(0.5, elapsedTime / halfLife);

    const generateDataPoints = (count) => {
        return Array.from({ length: count }, (_, i) => {
            const x = (i * halfLife / (60 * 60 * 1000) / (count / 6)).toFixed(2);
            const y = (initialQuantity * Math.pow(0.5, i / (count / 6))).toFixed(2);
            return { x, y };
        });
    };

    const data = {
        datasets: [
            {
                label: 'Remaining Quantity',
                data: generateDataPoints(50), // Reduced from 100 to 50 for better performance
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
                fill: false
            },
            {
                label: 'Current Point',
                data: [{ x: currentPoint.toFixed(2), y: currentQuantity.toFixed(2) }],
                backgroundColor: 'red',
                borderColor: 'red',
                pointRadius: 6,
                pointHoverRadius: 8,
                showLine: false
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false, // Allow the chart to resize
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    boxWidth: 10, // Smaller legend items
                    padding: 5
                }
            },
            title: {
                display: true,
                text: 'Drug Half-Life Graph',
                font: {
                    size: 14 // Smaller title
                }
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += `${context.parsed.y} ${unit}`;
                        }
                        return label;
                    }
                }
            }
        },
        scales: {
            x: {
                type: 'linear',
                title: {
                    display: true,
                    text: 'Time (hours)',
                    font: {
                        size: 10 // Smaller axis title
                    }
                },
                min: 0,
                max: Math.max(5 * halfLife / (60 * 60 * 1000), currentPoint * 1.1),
                ticks: {
                    font: {
                        size: 8 // Smaller tick labels
                    }
                }
            },
            y: {
                title: {
                    display: true,
                    text: `Quantity (${unit})`,
                    font: {
                        size: 10 // Smaller axis title
                    }
                },
                min: 0,
                max: initialQuantity,
                ticks: {
                    font: {
                        size: 8 // Smaller tick labels
                    }
                }
            }
        }
    };

    return (
        <div style={{ height: '300px', width: '100%' }}> {/* Set a fixed height */}
            <Line data={data} options={options} />
        </div>
    );
}

function Drug({ selectedDrug, quantity, handleDrugChange, handleQuantityChange, handleButtonClick, timers, formatTime, setTimers, handleCustomNameChange, customName, handleCustomDurationChange, customDuration }) {
    const handleClearTimers = () => {
        if (window.confirm('Are you sure you want to clear all timers?')) {
            setTimers([]);
            Cookies.remove('timers');
        }
    };

    return (
        <div>
            <div className="drug-selection-container">
                <div className="drug-selection">
                    <label htmlFor="drug-select">Choose a chemical:</label>
                    <select id="drug-select" onChange={handleDrugChange}>
                    <option value="caffeine">Caffeine</option>
                    <option value="cocaine">Cocaine</option>
                    <option value="mdma">MDMA</option>
                    <option value="ketamine">Ketamine</option>
                    <option value="gbl">GBL</option>
                    <option value="ghb">GHB</option>
                    <option value="lsd">LSD</option>
                    <option value="nicotine">Nicotine</option>
                    <option value="sildenafil">Sildenafil</option>
                    <option value="adderall">Adderall</option>
                    <option value="adderall_xr">Adderall XR</option>
                    <option value="vyvanse">Vyvanse</option>
                    <option value="ritalin">Ritalin</option>
                    <option value="concerta">Concerta</option>
                    <option value="custom">Custom</option>
                    </select>
                </div>
                <div className="drug-info">
                    {selectedDrug === 'custom' && (
                        <>
                            <div>
                                <label htmlFor="custom-name-input"><strong>Custom drug name:</strong></label>
                                <input
                                    type="text"
                                    id="custom-name-input"
                                    onChange={handleCustomNameChange}
                                    value={customName}
                                />
                            </div>
                            <div>
                                <label htmlFor="custom-duration-input"><strong>Custom half-life duration:</strong></label>
                                <input
                                    type="number"
                                    id="custom-duration-input"
                                    onChange={handleCustomDurationChange}
                                    value={customDuration}
                                    min="0"
                                />
                                <span><strong> hours</strong></span>
                            </div>
                        </>
                    )}
                    
                    <div>
                        <label htmlFor="quantity-input"><strong>Enter quantity:</strong></label>
                        <input
                            type="number"
                            id="quantity-input"
                            onChange={handleQuantityChange}
                            value={quantity}
                            min="0"
                        />
                        <span><strong> {selectedDrug === 'lsd' ? 'µg' : 'mg'}</strong></span>
                    </div>
                </div>
            </div>
            <button onClick={handleButtonClick}>Take it</button>
            <button onClick={handleClearTimers}>Clear Timers</button>
            <ul>
                {timers.map((timer, index) => (
                    <li key={index}>
                        {timer.drug} - ends at {new Date(timer.endTime).toLocaleTimeString()} - 
                        {formatTime(timer.remainingTime)} remaining - 
                        {timer.remainingQuantity ? timer.remainingQuantity.toFixed(2) : '0.00'}{timer.unit} left
                        <HalfLifeGraph 
                            initialQuantity={timer.initialQuantity} 
                            halfLife={timer.halfLife} 
                            unit={timer.unit} 
                            elapsedTime={Date.now() - (timer.endTime - timer.halfLife * 2)}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
}

function App() {
    const [drugHalfLives, setDrugHalfLives] = useState({
        caffeine: 5 * 60 * 60 * 1000, // 5 hours in milliseconds
        cocaine: 1 * 60 * 60 * 1000, // 1 hour in milliseconds
        mdma: 8 * 60 * 60 * 1000, // 8 hours in milliseconds
        ketamine: 2.5 * 60 * 60 * 1000, // 2.5 hours in milliseconds
        gbl: 0.5 * 60 * 60 * 1000, // 0.5 hours in milliseconds
        ghb: 0.5 * 60 * 60 * 1000, // 0.5 hours in milliseconds
        lsd: 3.5 * 60 * 60 * 1000, // 3.5 hours in milliseconds
        nicotine: 2 * 60 * 60 * 1000, // 2 hours in ms
        sildenafil: 4 * 60 * 60 * 1000, // 4 hours in milliseconds
        adderall: 10 * 60 * 60 * 1000, // 10 hours in milliseconds
        adderall_xr: 12 * 60 * 60 * 1000, // 12 hours in milliseconds
        vyvanse: 12 * 60 * 60 * 1000, // 12 hours in milliseconds
        ritalin: 3.5 * 60 * 60 * 1000, // 3.5 hours in milliseconds
        concerta: 12 * 60 * 60 * 1000, // 12 hours in milliseconds
    });
    const [selectedDrug, setSelectedDrug] = useState('caffeine');
    const [quantity, setQuantity] = useState('');
    const [customName, setCustomName] = useState('');
    const [customDuration, setCustomDuration] = useState('');
    const [timers, setTimers] = useState([]);

    useEffect(() => {
        const savedTimers = Cookies.get('timers');
        if (savedTimers) {
            setTimers(JSON.parse(savedTimers));
        }
    }, []);

    const handleDrugChange = (event) => {
        setSelectedDrug(event.target.value);
    };

    const handleQuantityChange = (event) => {
        setQuantity(Number(event.target.value));
    };

    const handleCustomNameChange = (event) => {
        setCustomName(event.target.value);
    };

    const handleCustomDurationChange = (event) => {
        setCustomDuration(Number(event.target.value));
    };
    const drugDurations = drugHalfLives;

    const handleButtonClick = () => {
        if (!quantity) {
            alert('Please select a quantity.');
            return;
        }

        let drugName = selectedDrug;
        let duration;

        if (selectedDrug === 'custom') {
            if (!customName) {
                alert('Please enter a custom drug name.');
                return;
            }
            if (!customDuration) {
                alert('Please enter a custom duration.');
                return;
            }
            drugName = customName;
            duration = customDuration * 60 * 60 * 1000; // Convert hours to milliseconds
        } else {
            duration = drugDurations[selectedDrug];
        }

        const unit = selectedDrug === 'lsd' ? 'µg' : 'mg';
        const timer = {
            drug: `${drugName} (${quantity}${unit})`,
            endTime: Date.now() + duration,
            initialQuantity: quantity,
            remainingQuantity: quantity,
            halfLife: duration / 2, // Store the half-life for each timer
            unit: unit, // Store the unit for each timer
        };

        setTimers((prevTimers) => {
            const updatedTimers = [...prevTimers, timer];
            Cookies.set('timers', JSON.stringify(updatedTimers), { expires: 7 });
            return updatedTimers;
        });

        if (selectedDrug === 'custom') {
            setDrugHalfLives((prevHalfLives) => ({
                ...prevHalfLives,
                [drugName]: duration / 2, // Store half-life, not full duration
            }));
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setTimers((prevTimers) =>
                prevTimers
                    .map((timer) => {
                        const currentTime = Date.now();
                        const remainingTime = timer.endTime - currentTime;
                        const elapsedTime = currentTime - (timer.endTime - timer.halfLife * 2);
                        const halfLives = elapsedTime / timer.halfLife;
                        const remainingQuantity = timer.initialQuantity * Math.pow(0.5, halfLives);
                        return { ...timer, remainingTime, remainingQuantity, elapsedTime };
                    })
                    .filter((timer) => timer.remainingTime > 0)
            );
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const formatTime = (milliseconds) => {
        const totalSeconds = Math.max(0, Math.floor(milliseconds / 1000));
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return `${hours}h ${minutes}m ${seconds}s`;
    };

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <h1>Welcome to <a href="http://halflife.institute">http://halflife.institute</a></h1>
                <p>
                    Pharmacological half-life is the time taken for
                    concentration of a medication to decrease from its maximum
                    concentration to half in blood plasma. Input what you take
                    when you take it, and this tool will estimate when half the
                    dose remains in your system based on the drug's half-life.
                </p>
                <p>
                    Keep in mind that a drug's half-life is just one factor in
                    the overall duration of its effects. The onset, peak, and
                    total duration can vary widely depending on the substance,
                    dose, route of administration, individual metabolism, and
                    other factors.
                </p>
                <p>
                    For example, even though the half-life of caffeine is
                    around 5 hours, its stimulant effects typically peak within
                    an hour and mostly wear off after 4-6 hours. Alcohol has an
                    even shorter half-life of only 4-5 hours, but impairment
                    can last much longer after drinking stops, especially with
                    high doses. Keep in mind that there are a lot of variables
                    that go into the duration, including an individiual's
                    genetics, so this is given entirely as an estimate.
                </p>
                <p>
                    Additionally, drug interactions can significantly impact a
                    substance's metabolism and clearance time. For more
                    information on half-lives and factors affecting drug
                    duration, check out <a
                    href="https://en.wikipedia.org/wiki/Drug_half-life"
                    target="_blank" rel="noopener noreferrer">this
                    overview</a>.
                </p>
                <Drug 
                    customDuration={customDuration}
                    customName={customName}
                    formatTime={formatTime}
                    handleButtonClick={handleButtonClick}
                    handleCustomDurationChange={handleCustomDurationChange}
                    handleCustomNameChange={handleCustomNameChange}
                    handleDrugChange={handleDrugChange}
                    handleQuantityChange={handleQuantityChange}
                    quantity={quantity}
                    selectedDrug={selectedDrug}
                    setTimers={setTimers}
                    timers={timers}
                />
            </header>
        </div>
    );

}

export default App;
